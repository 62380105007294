import { useNavigate } from "react-router-dom";
import useSessionStore, { FirstGameSession } from "../store/useSessionStore";
import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { Game } from "../types/Game";
import {
  callWebToAppFunction,
  loadExternalLink,
} from "../utils/androidCallers";
import {
  EventSource,
  EventTypes,
  MODALS,
  PopupType,
  WebToApp,
} from "../constants/Constants";
import { EventInfo } from "../types/window";
import { checkOnline, gameSessionStart } from "../services/backend-api";
import OfflineModal from "./Modal/ModalStyles/OfflineModal";
import Backdrop from "./Backdrop";
import ErrorModal from "./Modal/ModalStyles/ErrorModal";
import * as Sentry from "@sentry/browser";

import useGameStore from "../store/useGameStore";
import useEnvStore from "../store/useEnvStore";
import useDeepLinkBannerStore from "../store/useDeepLinkBannerStore";
import { AdFormat } from "../constants/AdConstants";
import { DeepLinkBanner } from "../types/DeepLinkBanner";
import useContentLinkBannerStore from "../store/useContentLinkStore";

const HeroBanner: React.FC = () => {
  const navigate = useNavigate();
  const setGameSessionId = useSessionStore((state) => state.setGameSessionId);
  const setIsUsersFirstGameSession = useSessionStore(
    (state) => state.setIsUsersFirstGameSession,
  );
  const homePageParameters = useSessionStore((state) => state.homePageParams);

  const [showLoader, setShowLoader] = useState(false);

  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const retryHandler = useRef<() => Promise<boolean>>();
  const games = useGameStore<{ [key: string]: Game[] }>((state) => state.games);

  const contentLinkBanners = useContentLinkBannerStore
    .getState()
    .getContentLinkBanners("hero_banner");

  const deepLinkBanners = useDeepLinkBannerStore
    .getState()
    .getDeepLinkBanners("hero_banner");

  useEffect(() => {
    if (currentModal) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "visible";
    }
  }, [currentModal]);

  const handleContentClick = async (gameId: string) => {
    // console.log("GameCards.tsx:Analytics:Calling button click analytics");
    const game = Object.values(games)
      .flat()
      .find((game: Game) => game.id === gameId);

    if (game) {
      const cardClickEvent: EventInfo = {
        eventType: EventTypes.card_click,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
          card_name: game.name,
          card_id: game.id,
          list_id: game.id,
          // card_index: index + 1,
          card_type: "hero_banner",
          referrer: "Homepage",
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        cardClickEvent,
        null,
        undefined,
      );
      // callWebToAppFunction(
      //   WebToApp.LOAD_EXTERNAL_LINK,
      //   "",
      //   "",
      //   "",
      //   {},
      //   "",
      //   null,
      //   "https://swiggy.onelink.me/888564224/acoahlyc",
      //   "com.android.chrome",
      // );
      await gameOpen(game);
    }
  };

  const gameOpen = async (game: Game) => {
    useEnvStore.getState().setIsOnline(await checkOnline());
    if (!useEnvStore.getState().isOnline) {
      const modal_viewed_event: EventInfo = {
        eventType: EventTypes.modal_viewed,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
          popup_type: PopupType.OFFLINE,
          source: EventSource.HOME_LOAD,
          game_name: game?.name,
          game: game?.id,
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        modal_viewed_event,
        null,
        undefined,
      );
      retryHandler.current = async () => {
        useEnvStore.getState().setIsOnline(await checkOnline());
        if (useEnvStore.getState().isOnline) {
          console.log(
            "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
          );
          await gameOpen(game);
          return true;
        }
        return false;
      };
      setCurrentModal(MODALS.OFFLINE);
      return false;
    } else {
      console.log("user is online");
      setShowLoader(true);
      const success = gameSessionStart({
        userId: homePageParameters.userId,
        gameId: game!.id,
        bundleId: homePageParameters.bundleId,
        startTime: new Date().toISOString(),
        accountId: homePageParameters.accountId,
        gameConfigId: game!.gameConfigId,
        webviewSessionId: homePageParameters.webviewSessionId,
      })
        .then(
          (res: {
            data: { gameSessionId: string; isUsersFirstGameSession: boolean };
          }) => {
            // console.log(
            //   "session id created time it took",
            //   performance.now() - time,
            //   "satrt tiume was:",
            //   time
            // );
            //gameSessionId = res?.data?.gameSessionId;
            let updateFirstSessionState: FirstGameSession = {
              isFirstSessionInBackend: res?.data?.isUsersFirstGameSession,
            };
            if (
              useSessionStore.getState().isUsersFirstGameSession
                ?.isFirstSessionInFrontend == null
            ) {
              updateFirstSessionState = {
                ...updateFirstSessionState,
                isFirstSessionInFrontend: res?.data?.isUsersFirstGameSession,
              };
            }
            setIsUsersFirstGameSession(updateFirstSessionState);
            setGameSessionId(res?.data?.gameSessionId);
            console.log(
              "Game session id created:",
              res?.data?.gameSessionId,
              " navigating to the game",
            );
            setShowLoader(false);
            navigate(`/game/${game?.id}`);
            setCurrentModal(MODALS.NONE);
            return true;
          },
        )
        .catch((err) => {
          Sentry.captureException(err);
          console.error("gameSessionStart error api: ", err);
          const modal_viewed_event: EventInfo = {
            eventType: EventTypes.modal_viewed,
            eventProperties: {
              web_timestamp: Date.now(),
              internetstate: useEnvStore.getState().isOnline
                ? "online"
                : "offline",
              popup_type: PopupType.UNEXPECTED_ERROR,
              source: EventSource.HOME_LOAD,
              game_name: game?.name,
              game_id: game?.id,
            },
          };
          callWebToAppFunction(
            WebToApp.ANALYTICS_LISTENER,
            "",
            "",
            "",
            modal_viewed_event,
            null,
            undefined,
          );
          retryHandler.current = async () => {
            useEnvStore.getState().setIsOnline(await checkOnline());
            if (useEnvStore.getState().isOnline) {
              console.log(
                "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
              );
              return await gameOpen(game);
            }
            return false;
          };
          if (err?.response?.status === 408) setCurrentModal(MODALS.OFFLINE);
          else setCurrentModal(MODALS.UNEXPECTED_ERROR);
          setShowLoader(false);
          return false;
        });
      return success;
    }
  };

  const openDeepLink = (deepLinkBanner: DeepLinkBanner) => {
    const adClickEvent: EventInfo = {
      eventType: EventTypes.ad_click,
      eventProperties: {
        web_timestamp: Date.now(),
        internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
        ad_type: AdFormat.Banner,
        ad_server: "in-house",
        image_url: deepLinkBanner.imageUrl,
        external_link: deepLinkBanner.externalLink,
        placement: deepLinkBanner.placement,
      },
    };
    callWebToAppFunction(
      WebToApp.ANALYTICS_LISTENER,
      "",
      "",
      "",
      adClickEvent,
      null,
      undefined,
    );
    loadExternalLink(
      deepLinkBanner.externalLink.replace("{aaid}", homePageParameters.gaid!),
    );
  };

  const banners: { img_url: string; action: () => void }[] = [];

  if (useSessionStore.getState().homePageParams.sdkVersion) {
    if (contentLinkBanners)
      banners.push(
        ...contentLinkBanners.map((contentLinkBanner) => {
          return {
            img_url: contentLinkBanner.imageUrl,
            action: () => handleContentClick(contentLinkBanner.gameId),
          };
        }),
      );
  }

  if (useSessionStore.getState().homePageParams.sdkVersion) {
    if (deepLinkBanners)
      banners.push(
        ...deepLinkBanners.map((deepLinkBanner) => {
          return {
            img_url: deepLinkBanner.imageUrl,
            action: () => openDeepLink(deepLinkBanner),
          };
        }),
      );
  }

  useEffect(
    () =>
      banners.forEach((banner) => {
        const img = new Image();
        img.src = banner.img_url;
      }),
    [banners],
  );

  const [cur, setCur] = useState(0);

  const len = banners.length;

  const leftHandle = useCallback(() => {
    setCur(cur - 1 < 0 ? len - 1 : cur - 1);
  }, [cur, len]);

  const rightHandle = useCallback(() => {
    setCur(cur + 1 > len - 1 ? 0 : cur + 1);
  }, [cur, len]);

  useEffect(() => {
    const interval = setTimeout(() => {
      rightHandle();
    }, 5000);
    return () => clearTimeout(interval);
  }, [rightHandle, leftHandle]);

  const touchStart = useRef<number | null>(null);
  const touchEnd = useRef<number | null>(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e: React.TouchEvent) => {
    touchEnd.current = null; // otherwise the swipe is fired even with usual touch events
    touchStart.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e: React.TouchEvent) => {
    touchEnd.current = e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchStart.current - touchEnd.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    console.log("distance", distance);
    if (isLeftSwipe || isRightSwipe)
      console.log("swipe", isLeftSwipe ? "left" : "right");
    // add your conditional logic here
    if (isLeftSwipe) leftHandle();
    if (isRightSwipe) rightHandle();
  };

  return (
    <>
      <div
        className="flex flex-col bg-gray-100 p-4 pt-4 pb-0 align-middle"
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {/*<div
              className="absolute text-white font-black bg-black p-2 bg-opacity-50 backdrop-blur-sm rounded-3xl"
              style={{ top: "130px", left: "20px" }}
              onClick={leftHandle}
            >
              {"<"}
            </div>
            <div
              className="absolute text-white font-black bg-black p-2 bg-opacity-50 backdrop-blur-sm rounded-3xl"
              style={{ top: "130px", right: "20px" }}
              onClick={rightHandle}
            >
              {">"
            </div>*/}
        <div className="w-full max-w-4xl mx-auto overflow-hidden">
          {banners.map((banner, index) => {
            return (
              index === cur && (
                <a onClick={banner.action}>
                  <img src={banner.img_url} className="w-full rounded-3xl" />
                </a>
              )
            );
          })}
        </div>
      </div>
      {len > 1 && (
        <div className="flex flex-col bg-gray-100 p-4 pt-0 pb-0 items-center">
          <div>
            {banners.map((banner, index) => {
              return (
                <span
                  key={index}
                  onClick={() => setCur(index)}
                  className="inline-block"
                  style={{
                    marginLeft: "5px",
                    cursor: "pointer",
                    color: cur === index ? "salmon" : "pink",
                    textShadow: "0 3px 3px mistyrose",
                  }}
                >
                  &#9679;
                </span>
              );
            })}
          </div>
        </div>
      )}
      {currentModal == "OFFLINE" && (
        <OfflineModal
          open={currentModal == "OFFLINE"}
          onRetryClickedHandler={async () => {
            setShowLoader(true);
            if (retryHandler.current && (await retryHandler.current())) {
              setCurrentModal(MODALS.NONE);
            }
            setShowLoader(false);
          }}
          exitShow
        ></OfflineModal>
      )}
      {currentModal == "UNEXPECTED_ERROR" && (
        <ErrorModal
          open={currentModal == "UNEXPECTED_ERROR"}
          onRetryClickedHandler={async () => {
            setShowLoader(true);
            if (retryHandler.current && (await retryHandler.current())) {
              setCurrentModal(MODALS.NONE);
            }
            setShowLoader(false);
          }}
          exitShow
        ></ErrorModal>
      )}
      {showLoader && (
        <Backdrop show={true}>
          <div className="spinner"></div>
        </Backdrop>
      )}
    </>
  );
};
export default HeroBanner;
