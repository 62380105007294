import axios from "axios";
import axiosRetry from "axios-retry";
import {
  HomeRequest,
  HomeResponse,
  SessionEndRequest,
  SessionEndResponse,
  SessionStartRequest,
  SessionStartResponse,
} from "../types/BackendModels";

import * as Sentry from "@sentry/browser";

import { callWebToAppFunction } from "../utils/androidCallers";
import { EventTypes, WebToApp } from "../constants/Constants";
import useSessionStore from "../store/useSessionStore";
import { EventInfo } from "../types/window";
import useEnvStore from "../store/useEnvStore";
const { gameSessionId,homePageParams } = useSessionStore.getState();
const getInstance = () => {
  const instance = axios.create({
    baseURL: "/api",
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 3000,
  });

  axiosRetry(instance, {
    retries: 2,
    retryDelay: axiosRetry.exponentialDelay,
    shouldResetTimeout: true,
    retryCondition: (_error) => true,
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      Sentry.captureException(error);
      return Promise.reject(error);
    },
  );

  return instance;
};

export const checkOnline = () =>
  getInstance()
    .head("/online", {
      baseURL: "",
      "axios-retry": {
        retries: 1,
        retryDelay: () => 0,
      },
    })
    .then((_) => true)
    .catch((_) => false);

export const getHomeScreen = async (data: HomeRequest) => {
  try {
    const axiosInstance = getInstance();
    const customBody = {
      userId:useSessionStore.getState().homePageParams.userId,
    }
    const authtoken = callWebToAppFunction(
      WebToApp.GET_AUTHORIZATION_TOKEN,
      "",
      "",
      "",
      undefined,
      null,
      undefined,
      undefined,
      undefined,
      customBody
    ) ?? "";
    const response = await axiosInstance.post<HomeResponse>("homeScreen", data,{
      headers:{
        Authorization: authtoken
      }
    });
    console.log("data after the call:", response);
    if (response.status !== 200) {
      Sentry.captureMessage(
        `getHomeScreen response: {status: ${response.status}, message: ${response.data}} `,
        "fatal",
      );
    }
    return response; // This will include the response data, status, and other information
  } catch (error) {
    // Handle or throw the error as needed
    //console.log("error in gameSessionEnd api send analytics");
    useEnvStore.getState().setIsOnline(await checkOnline());
    const gaetHomeScreenErrEvent: EventInfo = {
      eventType: EventTypes.api_status,
      eventProperties: {
        web_timestamp: Date.now(),
        internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
        api_url: "homeScreen",
        response: error,
        game_session_id: gameSessionId,
      },
    };
    callWebToAppFunction(
      WebToApp.ANALYTICS_LISTENER,
      "",
      "",
      "",
      gaetHomeScreenErrEvent,
      null,
      undefined,
    );
    console.error("Error fetching home screen:", error);
    throw error;
  }
};

export const gameSessionStart = async (data: SessionStartRequest) => {
  try {
    const axiosInstance = getInstance();
    const updatedRequestBody = {
      ...data,
      sdkUUID : useSessionStore.getState().homePageParams.sdkUUID,
      sdkVersion: useSessionStore.getState().homePageParams.sdkVersion,
    }
    const customBody = {
      userId: data.userId,
      gameId: data.gameId,
      gameConfigId: data.gameConfigId,
      offerId: data.offerId
    }
    const authtoken = callWebToAppFunction(
      WebToApp.GET_AUTHORIZATION_TOKEN,
      "",
      "",
      "",
      undefined,
      null,
      undefined,
      undefined,
      undefined,
      customBody
    ) ?? "";
    const response = await axiosInstance.post<SessionStartResponse>(
      "gameSession/start",
      updatedRequestBody,
      {
        headers:{
          Authorization:authtoken
        }
      }
    );
    if (response.status !== 200) {
      Sentry.captureMessage(
        `gameSessionStart response: {status: ${response.status}, message: ${response.data}} `,
        "fatal",
      );
    }
    return response; // This will include the response data, status, and other information
  } catch (error) {
    // Handle or throw the error as needed
    //console.log("error in gameSessionStart api send analytics");
    useEnvStore.getState().setIsOnline(await checkOnline());
    const gameSessionStartApiErrEvent: EventInfo = {
      eventType: EventTypes.api_status,
      eventProperties: {
        web_timestamp: Date.now(),
        internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
        api_url: "gameSession/start",
        response: error,
        game_session_id: gameSessionId,
      },
    };
    callWebToAppFunction(
      WebToApp.ANALYTICS_LISTENER,
      "",
      "",
      "",
      gameSessionStartApiErrEvent,
      null,
      undefined,
    );
    console.error("Error starting game session:", error);
    throw error;
  }
};

export const gameSessionEnd = async (data: SessionEndRequest) => {
  try {
    const axiosInstance = getInstance();
    const updatedRequestBody = {
      ...data,
      sdkUUID : useSessionStore.getState().homePageParams.sdkUUID,
      sdkVersion: useSessionStore.getState().homePageParams.sdkVersion,
    }
    const customBody = {
      userId: data.userId,
      gameId: data.gameId,
      gameConfigId: data.gameConfigId,
      offerId: data.offerId,
      score: data.score,
    }
    const authtoken = callWebToAppFunction(
      WebToApp.GET_AUTHORIZATION_TOKEN,
      "",
      "",
      "",
      undefined,
      null,
      undefined,
      undefined,
      undefined,
      customBody
    ) ?? "";
    const response = await axiosInstance.post<SessionEndResponse>(
      "gameSession/end",
      updatedRequestBody,
      {
        headers:{
          Authorization: authtoken
        }
      }
    );
    if (response.status !== 200) {
      Sentry.captureMessage(
        `gameSessionEnd response: {status: ${response.status}, message: ${response.data}} `,
        "fatal",
      );
    }
    return response; // This will include the response data, status, and other information
  } catch (error) {
    // Handle or throw the error as needed
    //console.log("error in gameSessionEnd api send analytics");
    useEnvStore.getState().setIsOnline(await checkOnline());
    const gameSessionEndApiErrEvent: EventInfo = {
      eventType: EventTypes.api_status,
      eventProperties: {
        web_timestamp: Date.now(),
        internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
        api_url: "gameSession/end",
        response: error,
        game_session_id: gameSessionId,
      },
    };
    callWebToAppFunction(
      WebToApp.ANALYTICS_LISTENER,
      "",
      "",
      "",
      gameSessionEndApiErrEvent,
      null,
      undefined,
    );
    console.error("Error ending game session:", error);
    throw error;
  }
};

export const getEnv = async () => {
  try {
    const axiosInstance = getInstance();
    const response = await axiosInstance.get<{ [key: string]: string }>("/env");
    console.log("data after the call:", response);

    return response; // This will include the response data, status, and other information
  } catch (error) {
    // Handle or throw the error as needed

    console.error("Error fetching env:", error);
    throw error;
  }
};
