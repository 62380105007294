import { Outlet, } from "react-router-dom";
import Navbar from "../pages/Navigation/Navbar";
import usePageStore from "../store/usePageStore";
export const RootLayout = () => {
  const { shouldNavBarBeHidden } = usePageStore();
  return (
    <>
      <div className="flex flex-col min-h-screen">
        {!shouldNavBarBeHidden && <Navbar></Navbar>}
        <Outlet></Outlet>
      </div>
    </>
  );
};
