import ReactDOM from "react-dom/client";
import App from "./App";

import * as Sentry from "@sentry/react";
import { getEnv } from "./services/backend-api";
import useEnvStore from "./store/useEnvStore";

getEnv().then((res) => {
  useEnvStore.getState().setEnv(res.data["env"]);
  useEnvStore.getState().setSentryDsn(res.data["sentryDsn"]);
  Sentry.init({
    environment: useEnvStore.getState().env,
    dsn: useEnvStore.getState().sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.005, //  Capture 100% of the transactions
    profilesSampleRate: 0.005,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/yourserver\.io\/api/,
      // import.meta.env.VITE_BACKEND_URL,
    ],
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
      console.log(event);
      console.log(hint);
      return event;
    },
  });

  ReactDOM.createRoot(document.getElementById("root")!).render(
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>
  );
});
